<template>
  <nav
    aria-label="Progress"
    class="overflow-x-auto md:shadow rounded-lg"
  >
    <ol class="hidden md:block divide-y divide-gray-300 md:flex md:divide-y-0 bg-white overflow-y-hidden">
      <li
        v-for="(stage, index) in pipeline"
        :key="stage.name"
        class="relative md:flex-1 md:flex"
      >
        <a
          v-if="chosenStage && chosenStage.slug === stage.slug"
          href="javascript:;"
          class="w-full px-6 py-4 flex items-center text-sm font-medium"
          aria-current="stage"
        >
          <span
            v-if="candidateCount"
            class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-secondary rounded-full"
          >
            <span
              class="text-secondary"
            >{{ candidateCount[stage.slug].qualified }}</span>
          </span>
          <svg
            v-else
            class="animate-spin h-8 w-8 text-secondary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <span class="ml-2 text-sm font-medium text-secondary truncate max-w-100">{{ stage.name }}</span>
        </a>
        <a
          v-else
          href="javascript:;"
          class="w-full group flex items-center"
          @click="$emit('chosen-stage', stage)"
        >
          <span class="px-6 py-4 flex items-center text-sm font-medium">
            <span
              v-if="candidateCount"
              class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400"
            >
              <span
                class="text-gray-600 group-hover:text-gray-900"
              >{{ candidateCount[stage.slug].qualified }}</span>
            </span>
            <svg
              v-else
              class="animate-spin h-8 w-8 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>

            <span class="ml-2 text-sm font-medium text-gray-600 group-hover:text-gray-900 truncate max-w-100">{{ stage.name }}</span>
          </span>
        </a>
        <template v-if="(index !== pipeline.length - 1)">
          <div
            class="hidden md:block absolute top-0 right-0 h-full w-5"
            aria-hidden="true"
          >
            <svg
              class="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vector-effect="non-scaling-stroke"
                stroke="currentcolor"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </template>
      </li>
    </ol>
    <div class="md:hidden">
      <label
        for="pipeline-stage-select"
        class="text-sm"
      >
        Select a stage
      </label>
      <select
        v-if="candidateCount"
        id="pipeline-stage-select"
        name="selected-tab"
        class="w-full form-select rounded-lg mt-1"
        @change="$emit('chosen-stage', stage)"
      >
        <option
          v-for="(stage, stageIdx) in pipeline"
          :key="stageIdx"
        >
          {{ stage.name }} ({{ candidateCount[stage.slug].qualified }})
        </option>
      </select>
    </div>
  </nav>
</template>

<script>

export default {
  props: {
    pipeline: {
      type: Array,
      default: null
    },

    chosenStage: {
      type: Object,
      default: null
    },

    candidateCount: {
      type: Object,
      default: null
    }
  }
}
</script>
