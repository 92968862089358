<template>
  <!--
    TODO
    - remove jobs warning?
    - remove exam suites
    - remove subscription?
  -->
  <form
    class="px-6 py-6 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <div>
      <section>
        <label class="relative block">
          <span class="text-sm text-gray-800">Name of your assessment</span>
          <input
            v-model="$v.formResponses.role.$model"
            type="text"
            class="w-full mt-1 form-input"
            placeholder="e.g. Engineering, Content Editor, Marketing"
          >
        </label>
      </section>
    </div>

    <div
      v-if="havePlansLoaded && hasJobsLimit"
      class="my-3"
    >
      <Notice
        v-if="jobsCount > 0"
        class="my-6"
        variant="info"
      >
        <h3
          v-if="assessmentJobsLimit > 0"
          class="text-sm font-medium"
        >
          You’re using {{ jobsCount }} of {{ assessmentJobsLimit }}
          {{ assessmentJobsLimit > 1 ? 'jobs' : 'job' }}
        </h3>
        <h3
          v-else
          class="text-sm font-medium"
        >
          You’re using {{ jobsCount }}
          {{ jobsCount > 1 ? 'jobs' : 'job' }}
        </h3>
        <div
          v-if="!canAddJob"
          class="mt-2 text-sm text-yellow-700 duration-150 ease-in-out"
        >
          You’ll need to
          <router-link
            :to="{ name: 'settings-plan' }"
            class="font-medium underline hover:text-secondary"
          >
            upgrade your plan
          </router-link>
          before you can add another job
        </div>
      </Notice>
    </div>

    <section class="mx-auto text-right">
      <BaseButton
        class="mt-8"
        :loading="isProcessing"
        :disabled="!canSubmitForm || !canAddJob"
        @click="submit"
      >
        Create Assessment
      </BaseButton>
      <ErrorsInline v-if="errorMessage">
        {{ errorMessage }}
      </ErrorsInline>
    </section>
  </form>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import Notice from '@components/Notice'
import examsApi from '@api/exams'

import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsInline,
    Notice
  },

  mixins: [validationMixin],

  data() {
    return {
      isProcessing: false,
      errorMessage: null,
      level: 'standard',

      formResponses: {
        role: null,
        deadline: null
      },

      examSuites: null
    }
  },

  computed: {
    ...mapGetters({
      currentSubscription: 'subscriptions/currentSubscription',
      hasUnlimitedJobs: 'organisations/hasUnlimitedJobs',
      havePlansLoaded: 'plans/havePlansLoaded',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      jobsCount: 'jobs/jobsCount',
      openJobs: 'jobs/openJobs',
      plans: 'plans/tcPlans'
    }),

    /**
     * @return {Boolean}
     */
    canSubmitForm() {
      if (!this.$v.formResponses.$anyDirty) {
        return false
      }

      return !this.$v.formResponses.$anyError
    },

    /**
     * @return {Object}
     */
    currentPlan() {
      if (!this.currentSubscription) {
        return
      }
      return this.plans.find(plan => {
        return plan.slug === this.currentSubscription.planSlug
      })
    },

    /**
     * Can we add a job? Logic for allowing if a job can be added if using
     * a jobs limit on a plan
     *
     * @return {Boolean}
     */
    canAddJob() {
      if (!this.$usesFeature('jobs-limit')) {
        return true
      }
      if (!this.havePlansLoaded) {
        return false // Loading
      }
      if (this.hasUnlimitedJobs) {
        return true
      }

      return this.jobsCount < this.assessmentJobsLimit
    },

    /**
     * @return {Boolean}
     */
    hasJobsLimit() {
      return this.$usesFeature('jobs-limit')
    },

    /**
     * @return {Number}
     */
    assessmentJobsLimit() {
      if (!this.havePlansLoaded) {
        return null
      }
      if (this.hasUnlimitedJobs) {
        return 0
      }
      if (!this.currentPlan) {
        return 1
      }
      return this.currentPlan.assessmentJobsLimit
    }
  },

  validations: {
    formResponses: {
      role: {
        required
      }
    }
  },

  mounted() {
    this.getPlans()

    if (!this.$usesFeature('classic-jobs')) {
      this.fetchExamSuites()
    }
  },

  methods: {
    /**
     * Fetch the plans if we need to get them
     */
    getPlans() {
      if (this.havePlansLoaded) {
        this.getSubscriptions()
        return
      }
      this.$store.dispatch('plans/getPlans')
        .then(() => {
          this.getSubscriptions()
        })
    },

    /**
     * Fetch the subscriptions
     */
    getSubscriptions() {
      if (this.haveSubscriptionsLoaded) {
        return
      }
      this.$store.dispatch('subscriptions/getSubscriptions')
    },

    /**
     * Fetch the subscriptions
     */
    fetchExamSuites() {
      return examsApi.examSuites()
        .then(response => {
          this.examSuites = response
        })
        .catch(error => {
          console.error(error)
        })
    },

    submit() {
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true
      this.errorMessage = null

      let job = { name: this.formResponses.role }

      if (this.$usesFeature('classic-jobs')) {
        job = { ...job }
      } else {
        // @TODO - there will be many examSuites soon
        job = { ...job, examSuiteSlug: this.examSuites[0].slug }
      }

      // if (this.formResponses.deadline) {
      //   job['deadline'] = this.formResponses.deadline
      // }

      this.$store.dispatch('jobs/create', job)
        .then((job) => {
          this.$router.push({ name: 'client-assessments-exams', params: { id: job.uuid } })
        })
        .catch(error => {
          this.isProcessing = false
          if (error.response && error.response.data && error.response.data.errors) {
            if (Array.isArray(error.response.data.errors)) {
              this.errorMessage = error.response.data.errors[0]
            }
          }
        })
    }
  }
}
</script>
