<template>
  <div class="w-full p-16 bg-white">
    <div class="items-center justify-between xl:flex">
      <div>
        <h2 class="text-3xl">
          Testing starts with
          <span v-if="!$usesFeature('jobs-labels')">an assessment</span>
          <span v-else-if="$usesFeature('jobs-labels')">a job</span>
        </h2>

        <p class="mb-6 text-xl text-gray-600">
          Create
          <span v-if="!$usesFeature('jobs-labels')">an assessment,</span>
          <span v-else-if="$usesFeature('jobs-labels')">a job,</span>
          choose your tests, and then send to your candidates
        </p>
      </div>
      <CreateAssessmentButton size="large" />
    </div>

    <div class="mt-4">
      <video controls>
        <source
          src="~@assets/video/intro.mp4"
          type="video/mp4"
        >
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'

export default {
  components: {
    CreateAssessmentButton
  }
}
</script>
