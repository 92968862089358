<template>
  <BaseContainer>
    <AssessmentList v-if="$usesFeature('classic-jobs')" />
    <JobsEnhanced v-else />
  </BaseContainer>
</template>

<script>
import AssessmentList from '@components/ClientAssessments/AssessmentList'
import JobsEnhanced from '@components/Jobs/JobsEnhanced'

export default {
  page: {
    title: 'Assessments'
  },

  components: {
    AssessmentList,
    JobsEnhanced
  }
}
</script>
