<template>
  <form
    class="px-6 py-6 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <section>
      <label class="block relative">
        <span class="text-gray-800 text-sm">Name of your assessment</span>
        <input
          v-model="$v.formResponses.role.$model"
          type="text"
          class="form-input mt-1 w-full"
          placeholder="e.g. Head of Paid Search"
        >
      </label>
    </section>

    <section
      v-if="$usesFeature('candidate-wizard')"
      class="mt-6"
    >
      <div class="block relative">
        <label class="block text-gray-800 text-sm mb-1">
          Deadline (optional)
        </label>
        <DatePicker
          placeholder="Add a deadline date"
          :value="formResponses.deadline"
          @selected="formResponses.deadline = $event"
          @clear="formResponses.deadline = null"
        />
      </div>
    </section>

    <section class="mx-auto text-right">
      <BaseButton
        class="mt-8"
        :loading="isProcessing"
        :disabled="!canSubmitForm"
        @click="submit"
      >
        Save
      </BaseButton>
      <ErrorsInline v-if="errorMessage">
        {{ errorMessage }}
      </ErrorsInline>
    </section>
  </form>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import DatePicker from '@components/DatePicker'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { format, parseISO } from 'date-fns'

export default {
  components: {
    ErrorsInline,
    DatePicker
  },

  mixins: [validationMixin],

  props: {
    job: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      format,
      parseISO,

      isProcessing: false,
      errorMessage: null,

      formResponses: {
        role: null,
        deadline: null
      }
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    canSubmitForm() {
      if (this.$v.formResponses.$anyError) {
        return false
      }
      return !this.$v.formResponses.$anyError
    }
  },

  validations: {
    formResponses: {
      role: {
        required
      }
    }
  },

  created() {
    this.formResponses.role = this.job.name
    this.setJobDeadline()
  },

  methods: {
    testSelected(test) {
      return this.formResponses.tests.includes(test)
    },

    setJobDeadline() {
      if (!this.job.deadline) {
        return
      }
      this.formResponses.deadline = new Date(this.job.deadline)
    },

    submit() {
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true

      const job = {
        name: this.formResponses.role,
        deadline: this.formResponses.deadline ? format(this.formResponses.deadline, 'yyyy-MM-dd') : null
      }

      this.$store.dispatch('jobs/update', { id: this.job.uuid, job })
        .then(() => {
          this.$emit('close')
        })
        .catch(error => {
          this.isProcessing = false
          this.errorMessage = error.message
        })
    }
  }
}
</script>
