<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Assessments</BasePageHeading>
      </div>
      <BasePageActions>
        <CreateAssessmentButton />
      </BasePageActions>
    </BasePageHeader>

    <BasePageRefine>
      <SearchBox
        placeholder="Search assessments"
        @search="searchAssessments($event)"
      />
    </BasePageRefine>

    <BaseTabBar>
      <BaseTabs v-if="total.active !== 0 && total.archived !== 0">
        <BaseTab
          :to="{ name: 'client-assessments-list' }"
          :active="!showArchived"
        >
          Active <span v-if="total.active">({{ total.active.toLocaleString() }})</span>
        </BaseTab>
        <BaseTab
          :to="{ name: 'client-assessments-list', query: { show: 'archived' } }"
          :active="showArchived"
        >
          Archived <span v-if="total.archived">({{ total.archived.toLocaleString() }})</span>
        </BaseTab>
      </BaseTabs>
    </BaseTabBar>

    <!-- Show the empty state if it’s the first use or empty -->
    <BaseWrapper v-if="total.active === 0 && total.archived === 0 && !searchQuery">
      <JobsEmpty />
    </BaseWrapper>
    <BaseWrapper v-else>
      <BaseTable>
        <BaseTHead>
          <tr>
            <BaseTHeadTh width="30%">
              Name
            </BaseTHeadTh>
            <BaseTHeadTh
              align="right"
              width="20%"
            >
              Candidates
            </BaseTHeadTh>
            <BaseTHeadTh
              align="right"
              width="30%"
            >
              <BaseTableSortBy
                name="created_at"
                :sort-by="sortBy"
                :sort-order="sortOrder"
                @sortBy="changeSortBy($event)"
                @sortOrder="changeSortOrder($event)"
              >
                Created
              </BaseTableSortBy>
            </BaseTHeadTh>
            <BaseTHeadTh
              align="right"
              class="sr-only"
              width="30%"
            >
              Actions
            </BaseTHeadTh>
          </tr>
        </BaseTHead>
        <BaseTBody v-if="!loadingTableContent && assessments.length > 0">
          <tr
            v-for="assessment in assessments"
            :key="assessment.uuid"
          >
            <BaseTBodyTh>
              <router-link :to="{ name: 'client-assessments-show', params: { id: assessment.uuid } }">
                {{ assessment.name }}
              </router-link>
            </BaseTBodyTh>
            <BaseTBodyTd align="right">
              {{ assessment.candidatesCount }}
            </BaseTBodyTd>
            <BaseTBodyTd align="right">
              {{ formatDate(assessment.createdAt) }}
            </BaseTBodyTd>
            <BaseTBodyTd align="right">
              <div class="inline-flex space-x-2">
                <ArchiveAssessmentButton
                  v-if="!assessment.closedAt"
                  :job="assessment"
                  @updated="fetchAssessments()"
                />
                <UnarchiveAssessmentButton
                  v-else-if="assessment.closedAt"
                  :job="assessment"
                  @updated="fetchAssessments()"
                />
                <TooltipButton>
                  <template v-slot:content>
                    <BaseButton
                      variant="salmon"
                      size="small"
                      :to="{ name: 'client-assessments-show', params: { id: assessment.uuid } }"
                    >
                      <template slot="iconMiddle">
                        <Icon
                          view-box="0 0 24 24"
                          class="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <Users fill="none" />
                        </Icon>
                      </template>
                    </BaseButton>
                  </template>
                  <template v-slot:tooltip>
                    View candidates
                  </template>
                </TooltipButton>
              </div>
            </BaseTBodyTd>
          </tr>
        </BaseTBody>
        <BaseTBody
          v-else-if="!loadingTableContent && assessments.length === 0"
        >
          <tr>
            <BaseTBodyTd
              v-if="searchQuery"
              colspan="4"
              align="center"
            >
              Couldn’t find any
              <span v-if="showArchived">archived</span>
              assessments matching “{{ searchQuery }}”
            </BaseTBodyTd>
            <BaseTBodyTd
              v-else-if="!searchQuery && showArchived"
              colspan="4"
              align="center"
            >
              Your archived assessments will show here when archived
            </BaseTBodyTd>
            <BaseTBodyTd
              v-else-if="!searchQuery && !showArchived"
              colspan="4"
              align="center"
            >
              Your active assessments will show here
            </BaseTBodyTd>
          </tr>
        </BaseTBody>
        <BaseTBodyLoading
          v-else-if="loadingTableContent"
          :cols="4"
        >
        </BaseTBodyLoading>
      </BaseTable>
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Assessments</BasePageHeading>
      </div>
      <CreateAssessmentButton />
    </BasePageHeader>
    <BaseLoader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
// Components
import ArchiveAssessmentButton from '@components/ClientAssessments/ArchiveAssessmentButton'
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'
import JobsEmpty from '@components/Jobs/JobsEmpty'
import UnarchiveAssessmentButton from '@components/ClientAssessments/UnarchiveAssessmentButton'
import Icon from '@components/Icons/Icon'
import Users from '@components/Icons/Users'
import SearchBox from '@components/TUI/SearchBox'
import TooltipButton from '@components/TooltipButton'

// Utilites
import jobsApi from '@api/jobs'
import { formatDate } from '@utils/formatDate'
import { mapGetters } from 'vuex'
import states from '@api/states'

export default {
  components: {
    ArchiveAssessmentButton,
    CreateAssessmentButton,
    JobsEmpty,
    UnarchiveAssessmentButton,
    Icon,
    Users,
    TooltipButton,
    SearchBox
  },

  data() {
    return {
      states,
      formatDate,

      sortBy: 'created_at',
      sortOrder: 'desc',
      searchQuery: null,

      error: null,
      assessments: null,
      total: {
        active: null,
        archived: null
      },
      loadingTableContent: false
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.assessments) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Boolean}
     */
    showArchived() {
      return this.$route.query.show === 'archived'
    }
  },

  created() {
    this.fetchAssessments()
  },

  methods: {
    /**
     * Fetch assessments using Jobs API sending all filters
     *
     * @return void
     */
    fetchAssessments() {
      this.loadingTableContent = true

      jobsApi.index({
        show_archived: this.showArchived,
        sort_order: this.sortOrder,
        sort_by: this.sortBy,
        query: this.searchQuery
      })
        .then(response => {
          this.loadingTableContent = false
          this.assessments = response.data
          this.total.active = response.meta.total.active
          this.total.archived = response.meta.total.archived
        })
        .catch(error => {
          this.loadingTableContent = false
          this.error = error
          throw error
        })
    },

    /**
     * Trigger a change
     *
     * @param {string} field
     */
    changeSortBy(field) {
      this.sortBy = field

      this.fetchAssessments()
    },

    /**
     * Trigger a change
     *
     * @param {string} order
     */
    changeSortOrder(order) {
      this.sortOrder = order

      this.fetchAssessments()
    },

    /**
     * Set search query and re-run fetch
     *
     * @param {string} query
     * @return void
     */
    searchAssessments(query) {
      this.searchQuery = query
      this.fetchAssessments()
    }
  }
}
</script>
