<template>
  <div>
    <BaseButton
      v-if="$usesFeature('classic-jobs')"
      data-tour-tc="step-6"
      :size="size"
      @click="openAddJobModal"
    >
      <template slot="iconLeft">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5"
        >
          <Plus />
        </Icon>
      </template>
      <span v-if="!$usesFeature('jobs-labels')">Create Assessment</span>
      <span v-else-if="$usesFeature('jobs-labels')">Create Job</span>
    </BaseButton>

    <BaseButton
      v-if="$usesFeature('candidate-wizard')"
      :to="{name: 'client-assessments-create'}"
    >
      <template slot="iconLeft">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5 mr-1"
        >
          <Plus />
        </Icon>
      </template>
      <span v-if="!$usesFeature('jobs-labels')">Add Assessment</span>
      <span v-else-if="$usesFeature('jobs-labels')">Add Job</span>
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Create an assessment
          </h3>
          <button
            v-if="($tours['onboarding'] && !$tours['onboarding'].isRunning) || !$tours['onboarding']"
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <AddJobForm
        v-if="$usesFeature('classic-jobs')"
        data-tour-tc="step-7"
        @close="close"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import AddJobForm from '@components/Jobs/AddJobForm'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Modal,
    AddJobForm,
    Icon,
    Plus
  },

  props: {
    size: {
      type: String,
      default: 'standard'
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  methods: {
    close() {
      this.modalOpen = false
      this.$emit('close')
    },

    openAddJobModal() {
      this.modalOpen = true
    }
  }
}
</script>
