<template>
  <div>
    <header class="flex items-center justify-between h-20 max-w-screen-xl px-6 pb-8 mx-auto">
      <h1 class="text-2xl leading-tight">
        <span v-if="!$usesFeature('jobs-labels')">Assessments</span>
        <span v-else-if="$usesFeature('jobs-labels')">Jobs</span>
      </h1>

      <CreateAssessmentButton />
    </header>

    <div v-if="haveJobsLoaded && openJobs">
      <div
        v-if="openJobs.length === 0"
        class="max-w-screen-xl px-6 mx-auto"
      >
        <JobsEmpty />
      </div>
      <div
        v-else
      >
        <div
          class="max-w-screen-xl mx-auto"
        >
          <div class="px-6">
            <ul class="space-y-6">
              <li
                v-for="job in sortedOpenJobs"
                :key="job.uuid"
              >
                <div class="overflow-hidden bg-white rounded shadow">
                  <div class="items-center justify-between p-6 border-b border-gray-300 sm:flex">
                    <div class="flex items-center">
                      <div class="flex items-center">
                        <router-link
                          :to="{
                            name: 'client-assessments-show',
                            params: { id: job.uuid }
                          }"
                          class="block mb-2 hover:text-gray-700 md:mb-0"
                        >
                          <h2 class="text-2xl font-bold truncate">
                            {{ job.name }}
                          </h2>
                        </router-link>
                        <span class="mx-2 text-sm text-gray-700">({{ job.candidatesCount }})</span>
                      </div>
                      <ul class="space-x-4 md:ml-3">
                        <li
                          v-if="job.deadline"
                          class="inline-flex items-center px-2 py-0.5 rounded text-xs bg-gray-100 text-gray-800"
                        >
                          Deadline: <span class="ml-1 font-medium">{{ job.deadline }}</span>
                        </li>
                        <li
                          v-if="job.disqualified"
                          class="inline-flex items-center px-2 py-0.5 rounded text-xs bg-gray-100 text-gray-800 md:ml-3"
                        >
                          Disqualified: <span class="ml-1 font-medium">{{ job.disqualified.length }}</span>
                        </li>
                      </ul>
                    </div>

                    <JobActions
                      :job="job"
                      @job-updated="$store.dispatch('jobs/getJobs')"
                    />
                  </div>

                  <div class="p-4 md:p-0">
                    <PipelineNavigation
                      :pipeline="pipeline"
                      :candidate-count="job.candidatePipelineCount"
                      @chosen-stage="goToJob(job.uuid, $event)"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'
import JobsEmpty from '@components/Jobs/JobsEmpty'
import Loader from '@components/Loader'
import PipelineNavigation from '@components/PipelineNavigation'
import JobActions from '@components/Jobs/JobActions'

import { mapGetters } from 'vuex'

export default {
  components: {
    CreateAssessmentButton,
    JobsEmpty,
    Loader,
    PipelineNavigation,
    JobActions
  },

  data() {
    return {
      jobId: null,
      jobName: null
    }
  },

  computed: {
    ...mapGetters({
      haveJobsLoaded: 'jobs/haveJobsLoaded',
      openJobs: 'jobs/openJobs',
      pipeline: 'organisations/pipeline'
    }),

    /**
     * @return {Array}
     */
    tabs() {
      const tabs = []
      if (this.openJobs.length > 0) {
        tabs.push('open')
      }
      return tabs
    },

    /**
     * @return {Array}
     */
    sortedOpenJobs() {
      if (!this.openJobs.length) {
        return []
      }
      return this.openJobs.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }
  },

  created() {
    this.$store.dispatch('jobs/getJobs')
  },

  methods: {
    goToJob(jobUuid, stage) {
      this.$router.push({
        name: 'client-assessments-show',
        params: { id: jobUuid }
      })
    }
  }
}
</script>
